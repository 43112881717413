import React, { useState } from 'react';
import tourData from './BoracayTourData';
import classes from './BoracayTours.module.css';

  const BoracayTours = () => {
    const [selectedSeason, setSelectedSeason] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedChildAge, setSelectedChildAge] = useState('');
    const [islandHopping, setIslandHopping] = useState(false);
  
    const handleReserveNow = () => {
      window.open('https://docs.google.com/forms/d/1Jj2hAn4kOE5qsM4QqRAsyZ-BiCJclTfTc-ncrfAF60A/viewform?pli=1&edit_requested=true', '_blank');
    };

    // Convert string date to Date object for filtering
    const parseDate = (dateString) => (dateString ? new Date(dateString) : null);
  
    // Filter the tour data based on the selected filters
    const filteredTours = tourData.filter((tour) => {
      const matchesSeason = selectedSeason ? tour.rates.some(rate => rate.description.includes(selectedSeason)) : true;
      const matchesDate = (fromDate || toDate)
        ? (parseDate(fromDate) <= tour.endDate && parseDate(toDate) >= tour.startDate)
        : true;
      const matchesIslandHopping = islandHopping ? tour.inclusions.includes('Island Hopping with Buffet Lunch') : true;
      const matchesChildAge = selectedChildAge ? tour.childRates.some(rate => rate.includes(selectedChildAge)) : true;
  
      return matchesSeason && matchesDate && matchesIslandHopping && matchesChildAge;
    });
  
    return (
      <div>
        <div className={classes.filters}>
          <label>
            Season:
            <select value={selectedSeason} onChange={(e) => setSelectedSeason(e.target.value)}>
              <option value="">All</option>
              <option value="Regular">Regular</option>
              <option value="High">High</option>
              <option value="Super Peak">Super Peak</option>
            </select>
          </label>
          <label>
            From Date:
            <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
          </label>
          <label>
            To Date:
            <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
          </label>
          <label>
            Child Age:
            <select value={selectedChildAge} onChange={(e) => setSelectedChildAge(e.target.value)}>
              <option value="">Select Age</option>
              <option value="0-2">0-2 yrs old</option>
              <option value="3-4">3-4 yrs old</option>
              <option value="5-6">5-6 yrs old</option>
              <option value="7up">7 yrs old and up</option>
            </select>
          </label>
          <label className={classes.islandHopping}>
            Island Hopping:
            <input type="checkbox" checked={islandHopping} onChange={(e) => setIslandHopping(e.target.checked)} />
          </label>
        </div>
        
        <section className={classes.tourDetails}>
          <h2>Boracay Tour Packages</h2>
          <div className={classes.tourGrid}>
            {filteredTours.map((tour) => (
              <div key={tour.id} className={classes.tourCard}>
                <img src={tour.image} alt={tour.title} className={classes.tourImage} />
                <div className={classes.tourContent}>
                  <h4 className={classes.tourTitle}>{tour.title}</h4>
  
                  <div className={classes.mainPrice}>
                    <strong>Starting From: </strong>
                    {selectedSeason
                      ? tour.rates
                          .filter((rate) => rate.description.includes(selectedSeason))
                          .map((rate, index) => (
                            <span key={index}>{rate.rate}</span>
                          ))
                      : <span>{tour.rates[0].rate}</span> }
                  </div>
  
                  <div className={classes.inclusions}>
                    <h5>Inclusions:</h5>
                    <ul>
                      {tour.inclusions.map((inclusion, index) => (
                        <li key={index}>{inclusion}</li>
                      ))}
                    </ul>
                  </div>

                  <div className={classes.tourButtons}>
  `                  <button 
                      className={classes.reserveNowButton} 
                      onClick={handleReserveNow}
                    >
                      Reserve Now
                    </button>
                  </div>`

                </div>
              </div>
            ))}
          </div>
        </section>

      
      </div>
    );
  };
  
  
export default BoracayTours;
