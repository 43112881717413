import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Hero.module.css';

const Hero = () => {
    const navigate = useNavigate();

    return (
        <section className={classes.hero}>
            <div className={classes.heroContent}>
            <h1>Your Adventure Awaits</h1>
            <p>Discover the world with JL Travel Agency and make memories that last a lifetime.</p>
            <button onClick={() => navigate('/tour-packages')} className={classes.ctaButton}>Explore Destinations</button>
            </div>
        </section>
    );
};

export default Hero;