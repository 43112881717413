import panglaoImage from '../../assets/image/panglao.webp';
import countrySide from '../../assets/image/country-side.webp';


const toursData = [
    {
      id: 1,
      tour_type: 'countryside',
      title: 'Countryside Tour',
      location: 'Bohol, Philippines',
      description: `
        Experience the charm of Bohol’s countryside with a tour that showcases the island's natural beauty and
        rich cultural heritage. From the iconic Chocolate Hills and the tranquil Loboc River cruise to the
        captivating Tarsier Sanctuary and historic Baclayon Church, this tour immerses you in the best of Bohol’s
        landscapes and local life. Enjoy lush greenery, scenic vistas, and the warm hospitality of the Boholanos
        as you explore one of the Philippines' most enchanting destinations.
        Our Bohol Countryside Tour package will take you to Bohol’s most popular tourist destinations such as
        the world-famous Chocolate Hills, Loboc River, Man-Made Forest, Tarsier Sanctuary, Blood Compact
        Shrine, and many more.      
      `,
      image: countrySide,
      price: `2,500.00`,
      bookings: '1000++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Licensed Tour Driver and Photographer',
        'Pick up Guests @ Any Point Along Panglao and Tagbilaran City',
        'Drop Guests @ Any Point Along Panglao and Tagbilaran City',
        'Tour Duration (08:00AM – 05:00PM)',
      ],
      exclusions: [
        'Entrance Fees',
        'Lunch @ Loboc or Loay River Floating Restaurant (Cruise)',
        'All Rides',
      ],
    },
    {
      id: 2,
      tour_type: 'joiner',
      title: 'Joiner Tour',
      location: 'Bohol, Philippines',
      description: 'Join a group of fellow travelers to explore the famous spots of Bohol at an affordable price.',
      image: panglaoImage,
      price: `980.00`,
      bookings: '1500++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Pick up Guests @ Any Point Along Panglao and Tagbilaran City',
        'Tour Duration (08:00AM – 05:00PM)',
      ],
      exclusions: [
        'Entrance Fees',
        'Lunch @ Loboc or Loay River Floating Restaurant (Cruise)',
      ],
    },
    {
      id: 3,
      tour_type: 'island',
      title: 'Panglao Island Hopping',
      location: 'Panglao, Bohol',
      description: 'Enjoy an exciting island hopping tour around Panglao. Experience dolphin watching, snorkeling, and visit pristine islands.',
      image: panglaoImage,
      price: `1,100.00`,
      bookings: '2000++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        '1 Captain Boatman',
        'Snorkeling Gears',
        'Complete Set of Life Vest / Passenger',
        'Environmental Fee',
        'Lunch',
      ],
      exclusions: [
        'All rides and activities not mentioned.',
      ],
    },
    {
      id: 4,
      tour_type: 'firefly',
      title: 'Firefly Watching / Whale Shark Watching',
      location: 'Bohol, Philippines',
      description: 'Experience the magic of watching fireflies in the evening or go whale shark watching during the day.',
      image: panglaoImage,
      price: `980.00`,
      bookings: '500++ booked',
      inclusions: [
        'Fully Air Conditioned Vehicle w/ Fuel',
        'Pick up & Dropping Point: Panglao Beach Area',
        'Complete Set of Life Vest / Passenger',
      ],
      exclusions: [
        'Snorkeling Gears',
        'Lunch',
      ],
    },
  ];
  
  export default toursData;
  