import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import BoracayTours from './BoracayTours';
import TourTabs from './TourTabs';
import countrysideImage from '../../assets/image/country-side-tour.jpg';
import joinerImage from '../../assets/image/joiner.jpg';
import islandHoppingImage from '../../assets/image/island-hopping.webp';
import fireflyImage from '../../assets/image/whale-watching.jpg';
import classes from './BoholTours.module.css';

const tourData = {
  tours: [
    {
      id: 1,
      tour_type: 'countryside',
      title: 'Countryside Tour',
      location: 'Bohol, Philippines',
      badge: 'Popular Tour',
      price: 'PHP 2,500.00',
      image: countrysideImage,
      buttonText: 'Book Now',
    },
    {
      id: 2,
      tour_type: 'joiner',
      title: 'Joiner Tour',
      location: 'Bohol, Philippines',
      badge: 'Customer Favorite',
      price: 'PHP 980.00',
      image: joinerImage,
      buttonText: 'Book Now',
    },
    {
      id: 3,
      tour_type: 'island',
      title: 'Island Hopping',
      location: 'Panglao, Philippines',
      badge: 'Best Seller',
      price: 'PHP 1,100.00',
      image: islandHoppingImage,
      buttonText: 'Book Now',
    },
    {
      id: 4,
      tour_type: 'firefly',
      title: 'Firefly Watching',
      location: 'Bohol, Philippines',
      badge: 'Highly Rated',
      price: 'PHP 980.00',
      image: fireflyImage,
      buttonText: 'Book Now',
    },
  ]
};

const BoholTours = () => {
  const [activeTab, setActiveTab] = useState('bohol');
  const navigate = useNavigate();

  const handleSeeMore = (tourType) => {
    navigate(`/tour-details/${tourType}`);
  };

  const handleBookNow = () => {
    window.open('https://docs.google.com/forms/d/1Jj2hAn4kOE5qsM4QqRAsyZ-BiCJclTfTc-ncrfAF60A/viewform?pli=1&edit_requested=true', '_blank');
  };

  return (
    <>
      <PublicHeader />
      <section className={classes.tourDetails}>
        <h2>Tours & Activities</h2>
        <TourTabs activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 'bohol' && (
          <div className={classes.tourGrid}>
            {tourData.tours.map((tour) => (
              <div key={tour.id} className={classes.tourCard}>
                <img src={tour.image} alt={tour.title} className={classes.tourImage} />
                <div className={classes.tourContent}>
                  <p className={classes.tourLocation}>{tour.location}</p>
                  <h4 className={classes.tourTitle}>{tour.title}</h4>
                  
                  {/* Badge for Popularity */}
                  <div className={classes.badge}>
                    <span>{tour.badge}</span>
                  </div>

                  <span className={classes.tourPrice}>Starting From: {tour.price}</span>
                  <div className={classes.tourButtons}>
                    <button className={classes.bookButton} onClick={handleBookNow}>Reserve Now</button>
                    <button
                      className={classes.seeMoreButton}
                      onClick={() => handleSeeMore(tour.tour_type)}
                    >
                      See More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {activeTab === 'boracay' && <BoracayTours />}
      </section>
      <PublicFooter />
    </>
  );
};

export default BoholTours;
