import React from 'react';
import { useParams } from 'react-router-dom';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import classes from './TourDetails.module.css';
import toursData from '../../assets/js/toursData'; 

const TourDetails = () => {
  const { tourType } = useParams(); // This will get the tour_type from the URL

  // Find the tour data based on the tour_type
  const tour = toursData.find((t) => t.tour_type === tourType);

  const handleBookNow = () => {
    window.open('https://docs.google.com/forms/d/1Jj2hAn4kOE5qsM4QqRAsyZ-BiCJclTfTc-ncrfAF60A/viewform?pli=1&edit_requested=true', '_blank');
  };

  if (!tour) {
    return (
      <>
        <PublicHeader />
        <section className={classes.error}>
          <h2>Tour Not Found</h2>
          <p>Sorry, we couldn't find the tour you are looking for.</p>
        </section>
        <PublicFooter />
      </>
    );
  }

  return (
    <>
      <PublicHeader />
      <section className={classes.tourDetails}>
        <div className={classes.detailsContent}>
          <div className={classes.imageSection}>
            <img src={tour.image} alt={tour.title} className={classes.tourImage} />
          </div>
          <div className={classes.descriptionSection}>
            <h2>{tour.title}</h2>
            <p>{tour.description}</p>
            <h4>Starting From: PHP {tour.price}</h4>
            <h5>Inclusions</h5>
            <ul>
              {tour.inclusions.map((inclusion, index) => (
                <li key={index}>{inclusion}</li>
              ))}
            </ul>
            <h5>Exclusions</h5>
            <ul>
              {tour.exclusions.map((exclusion, index) => (
                <li key={index}>{exclusion}</li>
              ))}
            </ul>
            <button className={classes.bookButton} onClick={handleBookNow}>Reserve Now</button>
          </div>
        </div>
      </section>
      <PublicFooter />
    </>
  );
};

export default TourDetails;
