import React from 'react';
import classes from './TourTabs.module.css';

const TourTabs = ({ activeTab, setActiveTab }) => {
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className={classes.tabs}>
      <button
        className={`${classes.tabButton} ${activeTab === 'bohol' ? classes.active : ''}`}
        onClick={() => handleTabClick('bohol')}
      >
        Bohol
      </button>
      <button
        className={`${classes.tabButton} ${activeTab === 'boracay' ? classes.active : ''}`}
        onClick={() => handleTabClick('boracay')}
      >
        Boracay
      </button>
      <button
        className={`${classes.tabButton} ${activeTab === 'cebu' ? classes.active : ''}`}
        onClick={() => handleTabClick('cebu')}
      >
        Cebu
      </button>
    </div>
  );
};

export default TourTabs;
