import React from 'react';
import { Helmet } from 'react-helmet';
import PublicHeader from '../../components/layout/Header/PublicHeader';
import PublicFooter from '../../components/layout/Footer/PublicFooter';
import birImage from '../../assets/image/permits/bir.jpg';
import businessPermitImage from '../../assets/image/permits/business-permit.jpg';
import businessPlateImage from '../../assets/image/permits/business-plate.jpg';
import dtiImage from '../../assets/image/permits/dti.jpg';
import classes from './WhyChooseJLTA.module.css';

const WhyChooseJLTA = () => {
  return (
    <>
      <Helmet>
        <title>Why Choose JL Travel Agency? - Proof of Legitimacy</title>
        <meta name="description" content="View our business permits and certifications to learn why JL Travel Agency is a trusted and legitimate travel partner." />
      </Helmet>
      <PublicHeader />
      <div className={classes.container}>
        <h1>Why Choose JL Travel Agency?</h1>
        <p>We are a certified and legitimate travel agency. Below are our business permits and certifications:</p>
        <div className={classes.permitContainer}>
          <div className={classes.permitItem}>
            <img src={birImage} alt="BIR Registration" className={classes.permitImage} />
            <p>BIR Registration Certificate</p>
          </div>
          <div className={classes.permitItem}>
            <img src={businessPermitImage} alt="Mayor's Permit" className={classes.permitImage} />
            <p>Mayor's Permit</p>
          </div>
          <div className={classes.permitItem}>
            <img src={businessPlateImage} alt="Business Plate" className={classes.permitImage} />
            <p>Business Plate (2024)</p>
          </div>
          <div className={classes.permitItem}>
            <img src={dtiImage} alt="DTI Certificate" className={classes.permitImage} />
            <p>DTI Certificate</p>
          </div>
        </div>
      </div>
      <PublicFooter />
    </>
  );
};

export default WhyChooseJLTA;
