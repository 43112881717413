import boracayShoreTimeImage from '../../assets/image/hotels/shore-time.jpg';
import boracayGoldenPhoenixImage from '../../assets/image/hotels/golden-phoenix.jpg';
import boracayErusSuiteImage from '../../assets/image/hotels/erus-suite.jpg';
import boracayAzaleaImage from '../../assets/image/hotels/azalea.jpg';
import boracayBambooBeachImage from '../../assets/image/hotels/bamboo-beach.jpg';

const tourData = [
  {
    id: 1,
    title: '3D2N BORACAY Shore Time Hotel',
    price: 'P5,100/pax',
    rates: [
      { rate: 'P5,100/pax', description: 'Jun 1-October 15, 2024 (Regular Season)', season: 'Regular' },
      { rate: 'P5,500/pax', description: 'Oct 16-Dec 19, 2024 (High Season)', season: 'High' },
      { rate: 'P6,000/pax', description: 'Dec 20-Jan 5, 2025 (Super Peak Season)', season: 'Super Peak' },
    ],
    otherRates: ['4D3N - 6,400/PAX', '5D4N - 7,700/PAX', '6D5N - 9,100/PAX', '7D6N - 10,500/PAX', '8D7N - 11,900/PAX'],
    image: boracayShoreTimeImage,
    inclusions: [
      'Caticlan Airport Pick Up',
      'Caticlan Airport Drop off',
      'Round Trip Boat Transfers',
      'Round Trip Port to Hotel Transfers',
      'Terminal Fees',
      'Environmental Fees',
      'Daily Breakfast',
      'Hotel Accom - Deluxe Room',
      'Island Hopping with Buffet Lunch',
    ],
    childRates: [
      '0-2 yrs old - FREE OF CHARGE (Without Breakfast)',
      '3-4 yrs old - P1,100 (Without Breakfast)',
      '5-6 yrs old - P2,300 (Without Breakfast)',
      '7 yrs old and up - FULL RATE (With Breakfast)',
    ],
    additionalCharge: '4 yrs old and up - FULL RATE (P1,200/pax)',
  },
  {
    id: 2,
    title: '3D2N BORACAY Golden Phoenix Hotel',
    price: 'P4,900/pax',
    rates: [
      { rate: 'P4,900/pax', description: 'Until December 31, 2024 (Regular Season)', season: 'Regular' },
      { rate: 'P5,500/pax', description: 'Dec 24-25, 2024 / Dec 29-31, 2024 (Peak Season)', season: 'High' },
    ],
    otherRates: ['4D3N - 6,100/PAX', '5D4N - 7,300/PAX', '6D5N - 8,600/PAX', '7D6N - 9,900/PAX', '8D7N - 11,200/PAX'],
    image: boracayGoldenPhoenixImage,
    inclusions: [
      'Caticlan Airport Pick Up',
      'Caticlan Airport Drop off',
      'Round Trip Boat Transfers',
      'Round Trip Port to Hotels transfers',
      'Terminal Fees',
      'Environmental Fees',
      'Daily Breakfast',
      'Hotel Accom - Deluxe Room',
      'Island Hopping with Buffet Lunch',
    ],
    childRates: [
      '0-2 yrs old - FREE OF CHARGE (With Breakfast)',
      '3-5 yrs old - P1,200 (Without Breakfast)',
      '6-9 yrs old - P2,300 (Without Breakfast)',
      '10 yrs old and up - FULL RATE (With Breakfast)',
    ],
    additionalCharge: '4 yrs old and up - FULL RATE (P1,200/pax)',
  },
  {
    id: 3,
    title: '3D2N BORACAY Erus Suite Hotel',
    price: 'P4,700/pax',
    rates: [
      { rate: 'P4,700/pax', description: 'Until December 31, 2024 (Regular Season)', season: 'Regular' },
      { rate: 'P4,900/pax', description: 'Sept 30 - Oct 6, 2024 / Dec 23, 2024 (Peak Season)', season: 'High' },
    ],
    otherRates: ['4D3N - 5,800/PAX', '5D4N - 6,900/PAX', '6D5N - 8,100/PAX', '7D6N - 9,300/PAX', '8D7N - 10,500/PAX'],
    image: boracayErusSuiteImage,
    inclusions: [
      'Caticlan Airport Pick Up',
      'Caticlan Airport Drop off',
      'Round Trip Boat Transfers',
      'Round Trip Port to Hotel Transfers',
      'Terminal Fees',
      'Environmental Fees',
      'Daily Breakfast',
      'Hotel Accom - Deluxe Room',
      'Island Hopping with Buffet Lunch',
    ],
    childRates: [
      '0-2 yrs old - FREE OF CHARGE (With Breakfast)',
      '3-5 yrs old - P1,200 (Without Breakfast)',
      '6-9 yrs old - P2,300 (Without Breakfast)',
      '10 yrs old and up - FULL RATE (With Breakfast)',
    ],
    additionalCharge: '4 yrs old and up - FULL RATE (P1,200/pax)',
  },
  {
    id: 4,
    title: '3D2N BORACAY Azalea Hotels & Residences',
    price: 'P6,200/pax',
    rates: [
      { rate: 'P6,200/pax', description: 'Until March 31, 2025 (Regular Season)', season: 'Regular' },
      { rate: 'P6,800/pax', description: 'Aug 24-26, 2024 / Nov 1-3, 2024 (Peak Season)', season: 'High' },
      { rate: 'P8,990/pax', description: 'Chinese New Year', season: 'Super Peak' },
    ],
    otherRates: ['4D3N - 8,000/PAX', '5D4N - 9,900/PAX', '6D5N - 11,800/PAX', '7D6N - 13,800/PAX', '8D7N - 15,700/PAX'],
    image: boracayAzaleaImage,
    inclusions: [
      'Caticlan Airport Pick Up',
      'Caticlan Airport Drop off',
      'Round Trip Boat Transfers',
      'Round Trip Port to Hotel Transfers',
      'Terminal Fees',
      'Environmental Fees',
      'Daily Breakfast',
      'Hotel Accom - Deluxe Room',
      'Island Hopping with Buffet Lunch',
    ],
    childRates: [
      '0-2 yrs old - FREE OF CHARGE (With Breakfast)',
      '3-5 yrs old - P1,200 (Without Breakfast)',
      '6-9 yrs old - P2,300 (Without Breakfast)',
      '10 yrs old and up - FULL RATE (With Breakfast)',
    ],
    additionalCharge: '4 yrs old and up - FULL RATE (P1,200/pax)',
  },
  {
    id: 5,
    title: '3D2N BORACAY Bamboo Beach Resort',
    price: 'P5,600/pax',
    rates: [
      { rate: 'P5,600/pax', description: 'Until Dec 31, 2024 (Regular Season)', season: 'Regular' },
      { rate: 'P6,000/pax', description: 'Dec 20-Jan 5 (Super Peak Season)', season: 'Super Peak' },
    ],
    otherRates: ['5D4N - 6,700/PAX', '6D5N - 7,800/PAX', '7D6N - 9,000/PAX', '8D7N - 10,100/PAX'],
    image: boracayBambooBeachImage,
    inclusions: [
      'Caticlan Airport Pick Up',
      'Caticlan Airport Drop off',
      'Round Trip Boat Transfers',
      'Round Trip Port to Hotel Transfers',
      'Terminal Fees',
      'Environmental Fees',
      'Daily Breakfast',
      'Hotel Accom - Deluxe Room',
      'Island Hopping with Buffet Lunch',
    ],
    childRates: [
      '0-2 yrs old - FREE OF CHARGE (Without Breakfast)',
      '3-4 yrs old - P1,100 (Without Breakfast)',
      '5-6 yrs old - P2,300 (Without Breakfast)',
      '7 yrs old and up - FULL RATE (With Breakfast)',
    ],
    additionalCharge: '4 yrs old and up - FULL RATE (P1,200/pax)',
  },
];

export default tourData;
